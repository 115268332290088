<template>
  <div>
    <NavTitle title="岗位快照">
      <template #right>
        <div class="flex items-center">
          <el-date-picker
            class="mr10"
            style="width: 250px;"
            v-model="screenDate"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="请选择日期"
            @change="handleChange">
          </el-date-picker>
          <el-button type="primary" @click="getSnapshotList">查找</el-button>
        </div>
      </template>
    </NavTitle>
    <div class="pl20 pr20 mt16" v-loading="loading">
      <el-table
        :data="snapLists"
        style="width: 100%"
        border
        :header-cell-style="{background: '#F8F8F8', color: '#333'}"
        :span-method="spanMethod">
        <el-table-column 
          label="应用"  
          prop="productName">
        </el-table-column>
        <el-table-column 
          label="渠道"
          prop="ch">
        </el-table-column>
        <el-table-column 
          label="开店"
          prop="productName">
          <template slot-scope="scope">
            <div class="id-list">
              <span v-for="(id, index) in scope.row.ecAgentPids" :key="index">{{id}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column 
          label="转发"
          prop="productName">
          <template slot-scope="scope">
            <div class="id-list">
              <span v-for="(id, index) in scope.row.transPids" :key="index">{{id}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column 
          label="语音"
          prop="productName">
          <template slot-scope="scope">
            <div class="id-list">
              <span v-for="(id, index) in scope.row.anchorPids" :key="index">{{id}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column 
          label="剪辑"
          prop="productName">
          <template slot-scope="scope">
            <div class="id-list">
              <span v-for="(id, index) in scope.row.skillPids" :key="index">{{id}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column 
          label="线上推广"
          prop="productName">
          <template slot-scope="scope">
            <div class="id-list">
              <span v-for="(id, index) in scope.row.onlinePromoPids" :key="index">{{id}}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import { queryPostRoundSnapshotApi } from '@/api/admin.js'
export default {
  components: {
    NavTitle
  },
  data() {
    return {
      screenDate: '',
      snapLists: [],
      loading: false
    }
  },
  methods: {
    getSnapshotList() {
      if(!this.screenDate) return this.$tips({message: '请选择日期', type: 'warning'})
      this.loading = true
      queryPostRoundSnapshotApi({date: this.screenDate}).then(res => {
        console.log('快照列表', res)
        this.loading = false
        if(!res.data.snapLists) return
        let list = []
        const obj = {}
        for (const item of res.data.snapLists) {
          if(obj[item.productId]) {
            obj[item.productId].push(item)
          } else {
            obj[item.productId] = [item]
          }
        }
        for (const key in obj) {
          const arr = obj[key]
          if(arr) {
            arr.forEach((item, index) => {
              item.rowspan = index == 0 ? arr.length : 0
            })
            list = list.concat(arr)
          }
        }
        this.snapLists = list
        console.log('snapLists', this.snapLists)
      }).catch(err => {
        this.loading = false
        this.$tips({message: err, type: 'error'})
      })
    },
    handleChange() {
      if(!this.screenDate) {
        this.snapLists = []
      } else {
        this.getSnapshotList()
      }
    },
    spanMethod({ row, column, rowIndex, columnIndex }) {
      // console.log(row, column, rowIndex, columnIndex)
      if(columnIndex == 0) {
        return row.rowspan ? [row.rowspan, 1] : [0, 0]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.id-list {
  display: flex;
  flex-wrap: wrap;
  span {
    color: #199EFF;
    margin-right: 20px;
    word-break: break-word;
    white-space: nowrap;
  }
}
</style>